<template>
  <div>
    <div class="page">
      <div class="page-title">
        <h1>メール通知設定</h1>
      </div>
        <b-form>
          <div class="mt-4 card biz-primary">
            <div class="card-header">基本設定</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <b-form-checkbox-group style="float:left; font-size: 20px"
                    v-model="checkboxSelected"
                    :options="checkboxData"
                    class="mb-3"
                    value-field="value"
                    text-field="text"
                    stacked
                  ></b-form-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 card biz-primary" v-if="isEnableEstimate">
            <div class="card-header">見積購買・概算発注</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <b-form-checkbox-group style="float:left; font-size:20px;"
                    v-model="checkboxSelected"
                    :options="checkboxData2"
                    class="mb-3"
                    value-field="value"
                    text-field="text"
                    stacked
                  ></b-form-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 card biz-primary" v-if="isEnableContract">
            <div class="card-header">支払管理</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <b-form-checkbox-group style="float:left; font-size: 20px"
                    v-model="checkboxSelected"
                    :options="checkboxData3"
                    class="mb-3"
                    value-field="value"
                    text-field="text"
                    stacked
                  ></b-form-checkbox-group>
                </div>
              
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <j-button variant="primary" class="mr-4" size="lg" @click="save">
              保存
            </j-button>
            <j-button variant="light" size="lg" @click="$router.push(to='/profile')">
              キャンセル
            </j-button>
          </div>
        </b-form>
    </div>
  </div>
</template>

<script>
import UserInfoModel from '@/model/UserInfo.js';
import CompanyInfo from '@/model/CompanyInfo';
export default {
  data() {
    return {   
      form: [],
      selectedDeliverlyInterval: '1',
      showModal: false,
      checkboxSelected: [],
      mailCodeList: ['470','480','740','760','C40'],
      checkboxSelectedDayOfWeek: [],
      isEnableCatalog: false,
      isEnableEstimate: false,
      isProvisionalOrder: false,
      isEnableContract:false,
      checkboxData: [
        { text: '検収完了のご連絡', value: '740', disabled: false},
        { text: '検収取消のご連絡', value: '760', disabled: false }
      ],
      checkboxData2: [
        { text: '見積選考結果のご連絡（不採用）', value: '480', disabled: false },
        { text: '見積選考結果のご連絡（採用）', value: '470', disabled: false }
      ],
      checkboxData3: [
        { text: '契約登録申請承認完了のお知らせ', value: 'C40', disabled: false }
      ],
    };
  },
  computed: {
  },
  mounted :function(){
    //get MailSupplierSetting from database
    var codeList = [];
    UserInfoModel.getMailSupplierSetting(this.$store.state.user.companyCode,this.$store.state.user.displayCode).then(result => {
      this.mailCodeList.forEach(mailCode => {
        codeList.push(mailCode);
        result.data.forEach(getData => {
          if(mailCode === getData.mailCode && !getData.isActive){
            codeList.pop();
          }
        })
      })
      this.checkboxSelected = codeList;
    }).catch(reason => {
      this.$errorMessage(undefined, {reason})
      this.checkboxSelected = this.mailCodeList
    });
    //get role
    CompanyInfo.getBuyerUseFunction(this.$store.state.user.companyCode).then(result => {
      this.isEnableEstimate = result.data.isEnableEstimate
      this.isEnableContract = result.data.isEnableContract
    }).catch(reason => this.$errorMessage(undefined, {reason}));

  },
  methods: {
    save: function(){
      UserInfoModel.saveMailSupplierSetting(this.$store.state.user.companyCode, {
        checkboxSelected: this.checkboxSelected,
        mailCodeList: this.mailCodeList,
        updateUserCode: this.$store.state.user.displayCode,
        updateUserName: this.$store.state.user.name,
      }).then(result => {
        if(result.data.status === 200){
          this.$successMessage("情報を更新しました。");
          this.$router.push('/profile')
        }
        else{
          this.$errorMessage(result.data.message)
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
  }
};
</script>

<style scoped>
.custom-control{
  display: block;
}
.lable-control {
  margin-top: 9px;
}
.modal-title{
  color: blue;
  font-weight: bold;
}
.btn-group .btn{
  min-width: auto;
}
</style>
